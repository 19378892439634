import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import * as dat from 'lil-gui'
import galaxyVertexShader from './shaders/galaxy/vertex.glsl'
import galaxyFragmentShader from './shaders/galaxy/fragment.glsl'
import gsap from 'gsap'
import $ from 'jquery'

import Img1 from '../static/portrait-dark.jpg'
import Img2 from '../static/projects/norgaard/logo.jpg'
import Img3 from '../static/projects/norgaard/polo.png'
import Img5 from '../static/projects/norgaard/sac.png'
import Pdf1 from '../static/projects/norgaard/cg.pdf'
import Pdf2 from '../static/projects/norgaard/presentation.pdf'
import Img6 from '../static/projects/norgaard/snowboard.png'
import Img7 from '../static/projects/mapquizz/preview1.png'
import Img8 from '../static/projects/mapquizz/preview2.png'
import Img9 from '../static/projects/mapquizz/preview3.png'
import Img10 from '../static/projects/prism/preview1.png'
import Img11 from '../static/projects/prism/preview2.png'
import Img12 from '../static/projects/crypto-app/preview1.png'
import Img13 from '../static/projects/crypto-app/preview2.png'
import Img14 from '../static/projects/mystudyroom/1.jpg'
import Img15 from '../static/projects/mystudyroom/2.jpg'
import Img16 from '../static/projects/mystudyroom/3.jpg'
import Img17 from '../static/projects/mystudyroom/4.jpg'
import Img18 from '../static/projects/aboki/logo.png'
import Img19 from '../static/projects/aboki/mockup.jpg'
import Img20 from '../static/projects/mrld/preview1.png'
import Img21 from '../static/projects/mrld/preview2.png'

/**
 * Update age
 */
function getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}

/**
 * Base
 */
// Debug
// const gui = new dat.GUI()
// gui.close()

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

/**
 * Galaxy
 */
const parameters = {
    count: 200000,
    size: 0.005,
    radius: 5,
    branches: 3,
    spin: 1,
    randomness: 1,
    randomnessPower: 6,
    insideColor: '#7F2AAD',
    outsideColor: '#D48BFA'
}

let geometry = null
let material = null
let points = null

const generateGalaxy = () =>
{
    if(points !== null)
    {
        geometry.dispose()
        material.dispose()
        scene.remove(points)
    }

    /**
     * Geometry
     */
    geometry = new THREE.BufferGeometry()

    const positions = new Float32Array(parameters.count * 3)
    const randomness = new Float32Array(parameters.count * 3)
    const colors = new Float32Array(parameters.count * 3)
    const scales = new Float32Array(parameters.count * 1)

    const insideColor = new THREE.Color(parameters.insideColor)
    const outsideColor = new THREE.Color(parameters.outsideColor)

    for(let i = 0; i < parameters.count; i++)
    {
        const i3 = i * 3

        // Position
        const radius = Math.random() * parameters.radius

        const branchAngle = (i % parameters.branches) / parameters.branches * Math.PI * 2

        const randomX = Math.pow(Math.random(), parameters.randomnessPower) * (Math.random() < 0.5 ? 1 : - 1) * parameters.randomness * radius
        const randomY = Math.pow(Math.random(), parameters.randomnessPower) * (Math.random() < 0.5 ? 1 : - 1) * parameters.randomness * radius
        const randomZ = Math.pow(Math.random(), parameters.randomnessPower) * (Math.random() < 0.5 ? 1 : - 1) * parameters.randomness * radius

        positions[i3] = Math.cos(branchAngle) * radius
        positions[i3 + 1] = 0
        positions[i3 + 2] = Math.sin(branchAngle) * radius
    
        randomness[i3] = randomX
        randomness[i3 + 1] = randomY
        randomness[i3 + 2] = randomZ

        // Color
        const mixedColor = insideColor.clone()
        mixedColor.lerp(outsideColor, radius / parameters.radius)

        colors[i3] = mixedColor.r
        colors[i3 + 1] = mixedColor.g
        colors[i3 + 2] = mixedColor.b

        // Scale
        scales[i] = Math.random()
    }

    geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3))
    geometry.setAttribute('aRandomness', new THREE.BufferAttribute(randomness, 3))
    geometry.setAttribute('color', new THREE.BufferAttribute(colors, 3))
    geometry.setAttribute('aScale', new THREE.BufferAttribute(scales, 1))

    /**
     * Material
     */
    material = new THREE.ShaderMaterial({
        depthWrite: false,
        blending: THREE.AdditiveBlending,
        vertexColors: true,
        uniforms:
        {
            uTime: { value: 0 },
            uSize: { value: 30 * renderer.getPixelRatio() }
        },    
        vertexShader: galaxyVertexShader,
        fragmentShader: galaxyFragmentShader
    })

    /**
     * Points
     */
    points = new THREE.Points(geometry, material)
    scene.add(points)
}

// gui.add(parameters, 'count').min(100).max(1000000).step(100).onFinishChange(generateGalaxy)
// gui.add(parameters, 'radius').min(0.01).max(20).step(0.01).onFinishChange(generateGalaxy)
// gui.add(parameters, 'branches').min(2).max(20).step(1).onFinishChange(generateGalaxy)
// gui.add(parameters, 'randomness').min(0).max(2).step(0.001).onFinishChange(generateGalaxy)
// gui.add(parameters, 'randomnessPower').min(1).max(10).step(0.001).onFinishChange(generateGalaxy)
// gui.addColor(parameters, 'insideColor').onFinishChange(generateGalaxy)
// gui.addColor(parameters, 'outsideColor').onFinishChange(generateGalaxy)

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

    // Responsive
    updateVariables()
})

/**
 * Camera
 */
let fixedCamera = true
let cameraGroup = new THREE.Group
scene.add(cameraGroup)

// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.x = 3
camera.position.y = 3
camera.position.z = 3
cameraGroup.add(camera)

// const cameraFolder = gui.addFolder('Camera')
// cameraFolder.add(camera.position, 'x', -10, 50)
// cameraFolder.add(camera.position, 'y', -10, 50)
// cameraFolder.add(camera.position, 'z', -10, 50)
// cameraFolder.open()

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true
controls.enableRotate = false
controls.enableZoom = false

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    alpha: true
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Generate the first galaxy
 */
generateGalaxy()

/**
 * Camera intro animation
 */
let isIntroFinished = false
function introCameraAnimation() {
    var introCameraAnimationTl = gsap.timeline({onComplete: displayPlanetsHints});
    introCameraAnimationTl.fromTo(camera.position,
        {
            x: 50,
            y: 50,
            z: 50
        },
        {
            x: 5,
            y: 3,
            z: 0,
            duration: 5,
            ease: 'power2'
        }
    )
}

introCameraAnimation()

/**
 * HTML Elements
 */
let planetPosHelper = new THREE.Vector3(0, 0, 0)
// const planetHelperFolder = gui.addFolder('Planet Pos Helper');
// planetHelperFolder.close();
// planetHelperFolder.add(planetPosHelper, 'x', -5, 5);
// planetHelperFolder.add(planetPosHelper, 'y', -5, 5);
// planetHelperFolder.add(planetPosHelper, 'z', -5, 5);

let planets = [
    {
        position: new THREE.Vector3(1.9, 0, 0.8),
        element: document.querySelector('.planet-0')
    },
    {
        position: new THREE.Vector3(-1.9, 0, 1.8),
        element: document.querySelector('.planet-1')
    },
    {
        position: new THREE.Vector3(1.9, 0, -0.8),
        element: document.querySelector('.planet-2')
    },
    {
        position: new THREE.Vector3(-1.9, 0, -0.8),
        element: document.querySelector('.planet-3')
    },
    {
        position: new THREE.Vector3(1, 0, 1.5),
        element: document.querySelector('.planet-4')
    },
    {
        position: new THREE.Vector3(-1, 0, 0.5),
        element: document.querySelector('.planet-5')
    },
    {
        position: new THREE.Vector3(0, 0, -1.5),
        element: document.querySelector('.planet-6')
    }
]

planets.forEach((el, i) => {
    el.element.addEventListener('click', () => {
        if (fixedCamera == true) {
            return
        }
        let targetId = $(el.element).attr('data-id');
        var data = require('./projects_cryptoapp.json');
        let title = data.projects.find(projectEl => projectEl.id == targetId).title;
        let description = data.projects.find(projectEl => projectEl.id == targetId).description;
        let images = data.projects.find(projectEl => projectEl.id == targetId).images;

        $('.detailsModal .content h2 span').text(title);
        $('.detailsModal .content .description, .detailsModal .content .images').empty();
        description.forEach((el, i) => {
            switch (el.type) {
                case "p":
                    $('.detailsModal .content .description').append('<p><span>'+el.content+'</span></p>');
                    break;

                case "links":
                    $('.detailsModal .content .description').append('<div class="links">'+el.content+'</div>');
                    break;
            
                default:
                    $('.detailsModal .content .description').append(el.content);
                    break;
            }
            if(el.content.includes('age')) {
                $('#age').text(getAge('06/12/2001'));
            }
        });
        images.forEach((el, i) => {
            $('.detailsModal .content .images').append('<img src="'+el+'"></img>');
        });

        fixedCamera = true
        gsap.to(camera.position,
            {
                x: el.position.x + 1,
                y: 1,
                z: el.position.z + 1,
                duration: 2,
                ease: 'power3'
            }
        )
        setTimeout(() => {
            $('.detailsModal').addClass('visible');
            let contentTl = gsap.timeline()
            contentTl.from(".detailsModal .content h2 span, .detailsModal .content .description p span:not(#age), .detailsModal .content .description .links a",
                {
                    duration: 1,
                    delay: .3,
                    y: 200,
                    skewY: 10,
                    stagger: {
                        amount: .5
                    }
                }
            )
            contentTl.to('.detailsModal .content .images img',
                {
                    opacity: 1,
                    duration: .5
                },
                '>-0.2'
            )
            // contentTl.to('.detailsModal .content h2 span', { translateY: 0, duration: 0.5 })
            // var contentTexts = gsap.utils.toArray('.detailsModal .content .description p span:not(#age)')
            // contentTexts.forEach((el) => {
            //     contentTl.to(el, { translateY: 0, duration: 0.5 }, ">-0.2")
            // })
        }, 600)
    })
})

function displayPlanetsHints() {
    fixedCamera = false
    isIntroFinished = true
    $('.planet').addClass('visible')
}

$('.backArrow').click(() => {
    rotationOffsetTime = 0
    $('.detailsModal').removeClass('visible');
    setTimeout(() => {
        gsap.to(camera.position,
            {
                x: 5,
                y: 3,
                z: 0,
                duration: 3,
                ease: 'power1',
                onComplete: () => {fixedCamera = false}
            }
        )
    }, 300)
})

/**
 * Cursor
 */
const cursor = {}
cursor.x = 0
cursor.y = 0

window.addEventListener('mousemove', (event) => {
    cursor.x = event.clientX / sizes.width - 0.5
    cursor.y = event.clientY / sizes.height - 0.5
})

/**
 * Responsive
 */
let phonePortraitDisplay = false;
let phoneLandscapeDisplay = false;
let tabletPortraitDisplay = false;
let tabletLandscapeDisplay = false;
let pcDisplay = true;

function updateVariables() {
    // Update variables
    if(window.matchMedia("(max-width: 480px) and (orientation: portrait)").matches && phonePortraitDisplay == false) { // Phone portrait
        phonePortraitDisplay = true;
        phoneLandscapeDisplay = false;
        tabletPortraitDisplay = false;
        tabletLandscapeDisplay = false;
        pcDisplay = false;

        points.scale.set(0.5, 0.5, 0.5)
        parameters.count = 150000
        generateGalaxy()

        planets = [
            {
                position: new THREE.Vector3(2, 0, 0.4),
                element: document.querySelector('.planet-0')
            },
            {
                position: new THREE.Vector3(-1.9, 0, 1.8),
                element: document.querySelector('.planet-1')
            },
            {
                position: new THREE.Vector3(1.9, 0, -0.8),
                element: document.querySelector('.planet-2')
            },
            {
                position: new THREE.Vector3(-1.9, 0, -0.8),
                element: document.querySelector('.planet-3')
            },
            {
                position: new THREE.Vector3(1, 0, 1),
                element: document.querySelector('.planet-4')
            },
            {
                position: new THREE.Vector3(-1, 0, 0.5),
                element: document.querySelector('.planet-5')
            },
            {
                position: new THREE.Vector3(0, 0, -1.5),
                element: document.querySelector('.planet-6')
            }
        ]
    } else if(window.matchMedia("(min-width: 480px) and (max-width: 991px) and (orientation: landscape)").matches && phoneLandscapeDisplay == false) {
        phonePortraitDisplay = false;
        phoneLandscapeDisplay = true;
        tabletPortraitDisplay = false;
        tabletLandscapeDisplay = false;
        pcDisplay = false;

        points.scale.set(1.2, 1.2, 1.2)
        parameters.count = 100000
        generateGalaxy()

        planets = [
            {
                position: new THREE.Vector3(2.5, 0, 1),
                element: document.querySelector('.planet-0')
            },
            {
                position: new THREE.Vector3(-1.9, 0, 2.8),
                element: document.querySelector('.planet-1')
            },
            {
                position: new THREE.Vector3(1.9, 0, -0.8),
                element: document.querySelector('.planet-2')
            },
            {
                position: new THREE.Vector3(-2.9, 0, -1.8),
                element: document.querySelector('.planet-3')
            },
            {
                position: new THREE.Vector3(1, 0, 1),
                element: document.querySelector('.planet-4')
            },
            {
                position: new THREE.Vector3(-1, 0, 0.5),
                element: document.querySelector('.planet-5')
            },
            {
                position: new THREE.Vector3(0, 0, -1.5),
                element: document.querySelector('.planet-6')
            }
        ]
    } else if(window.matchMedia("(min-width: 768px) and (max-width: 991px) and (orientation: portrait)").matches && tabletPortraitDisplay == false) {
        phonePortraitDisplay = false;
        phoneLandscapeDisplay = false;
        tabletPortraitDisplay = true;
        tabletLandscapeDisplay = false;
        pcDisplay = false;

        parameters.count = 200000
        generateGalaxy()

        planets = [
            {
                position: new THREE.Vector3(1.9, 0, 0.8),
                element: document.querySelector('.planet-0')
            },
            {
                position: new THREE.Vector3(-1.9, 0, 1.8),
                element: document.querySelector('.planet-1')
            },
            {
                position: new THREE.Vector3(1.9, 0, -0.8),
                element: document.querySelector('.planet-2')
            },
            {
                position: new THREE.Vector3(-1.9, 0, -0.8),
                element: document.querySelector('.planet-3')
            },
            {
                position: new THREE.Vector3(1, 0, 1.5),
                element: document.querySelector('.planet-4')
            },
            {
                position: new THREE.Vector3(-1, 0, 0.5),
                element: document.querySelector('.planet-5')
            },
            {
                position: new THREE.Vector3(0, 0, -1.5),
                element: document.querySelector('.planet-6')
            }
        ]
    } else if(window.matchMedia("(min-width: 992px) and (max-width: 1199px) and (orientation: landscape)").matches && tabletLandscapeDisplay == false) { // Tablet landscape
        phonePortraitDisplay = false;
        phoneLandscapeDisplay = false;
        tabletPortraitDisplay = false;
        tabletLandscapeDisplay = true;
        pcDisplay = false;

        parameters.count = 200000
        generateGalaxy()

        planets = [
            {
                position: new THREE.Vector3(1.9, 0, 0.8),
                element: document.querySelector('.planet-0')
            },
            {
                position: new THREE.Vector3(-1.9, 0, 1.8),
                element: document.querySelector('.planet-1')
            },
            {
                position: new THREE.Vector3(1.9, 0, -0.8),
                element: document.querySelector('.planet-2')
            },
            {
                position: new THREE.Vector3(-1.9, 0, -0.8),
                element: document.querySelector('.planet-3')
            },
            {
                position: new THREE.Vector3(1, 0, 1.5),
                element: document.querySelector('.planet-4')
            },
            {
                position: new THREE.Vector3(-1, 0, 0.5),
                element: document.querySelector('.planet-5')
            },
            {
                position: new THREE.Vector3(0, 0, -1.5),
                element: document.querySelector('.planet-6')
            }
        ]
    } else if(window.matchMedia("(min-width: 1200px)").matches && pcDisplay == false) { // PC
        phonePortraitDisplay = false;
        phoneLandscapeDisplay = false;
        tabletPortraitDisplay = false;
        tabletLandscapeDisplay = false;
        pcDisplay = true;

        parameters.count = 200000
        generateGalaxy()

        points.scale.set(1, 1, 1)

        planets = [
            {
                position: new THREE.Vector3(1.9, 0, 0.8),
                element: document.querySelector('.planet-0')
            },
            {
                position: new THREE.Vector3(-1.9, 0, 1.8),
                element: document.querySelector('.planet-1')
            },
            {
                position: new THREE.Vector3(1.9, 0, -0.8),
                element: document.querySelector('.planet-2')
            },
            {
                position: new THREE.Vector3(-1.9, 0, -0.8),
                element: document.querySelector('.planet-3')
            },
            {
                position: new THREE.Vector3(1, 0, 1.5),
                element: document.querySelector('.planet-4')
            },
            {
                position: new THREE.Vector3(-1, 0, 0.5),
                element: document.querySelector('.planet-5')
            },
            {
                position: new THREE.Vector3(0, 0, -1.5),
                element: document.querySelector('.planet-6')
            }
        ]
    }
}

/**
 * Animate
 */
const clock = new THREE.Clock()
let previousTime = 0
let rotationOffsetTime = 0
let cameraBaseX = 5
let cameraBaseZ = -5

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - previousTime
    previousTime = elapsedTime

    // Update material
    if(!isIntroFinished) {
        material.uniforms.uTime.value = (Math.log(elapsedTime) + 5) * 3 + 10
    }

    // Update camera
    if(!fixedCamera) {
        camera.position.x = Math.cos(rotationOffsetTime * 0.01) * cameraBaseX
        camera.position.z = Math.sin(rotationOffsetTime * 0.01) * cameraBaseZ
        rotationOffsetTime += deltaTime
    }

    // Animate camera
    const parallaxX = cursor.x * 0.1
    const parallaxY = - cursor.y * 0.1

    cameraGroup.position.x += (parallaxX - cameraGroup.position.x) * 5 * deltaTime
    cameraGroup.position.y += (parallaxY - cameraGroup.position.y) * 5 * deltaTime

    // Update controls
    controls.update()

    // Update HTML elements
    for(const planet of planets)
    {
        const screenPosition = planet.position.clone()
        screenPosition.project(camera)

        const translateX = screenPosition.x * sizes.width * 0.5
        const translateY = - screenPosition.y * sizes.height * 0.5
        planet.element.style.transform = `translateX(${translateX}px) translateY(${translateY}px)`
    }

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()
updateVariables()